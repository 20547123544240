@font-face {
  font-family: "Santander Headline";
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderHeadline-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Headline";
  font-weight: bold;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderHeadline-BoldIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Headline";
  font-weight: normal;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderHeadline-It.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Headline";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderHeadline-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Headline";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderHeadlineLight.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Headline";
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderHeadlineLightIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderMicroText.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderMicroTextBd.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: bold;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderMicroTextBdIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: normal;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderMicroTextIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderMicroTextLt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderMicroTextLtIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderMicroTextSmBd.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 600;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderMicroTextSmBdIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 800;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderMicroTextXBd.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Micro Text";
  font-weight: 800;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderMicroTextXBdIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: bold;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderText-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: normal;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderText-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderText-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderText-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderTextSmBd.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 600;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderTextSmBdIt.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 800;
  font-style: normal;
  src: url("../assets/fonts/Santander/SantanderTextXBd.ttf") format("truetype");
}

@font-face {
  font-family: "Santander Text";
  font-weight: 800;
  font-style: italic;
  src: url("../assets/fonts/Santander/SantanderTextXBdIt.ttf") format("truetype");
}

//Html
html {
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

//Body
body {
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//Pages
section {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 48px 20px;
  margin: 0 auto;
  max-width: 600px;
  color: #333;
}

h2 {
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
}

p {
  margin: 1em 0;
}

a {
  text-decoration: none;
  color: #1ea7fd;
}

ul {
  padding-left: 30px;
  margin: 1em 0;
}

li {
  margin-bottom: 8px;
}

@media (min-width: 993px) {
  .hide-in-desktop {
    display: none;
  }
}

@media (max-width: 992px) {
  .hide-in-mobile {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}